import * as React from "react"
import styled from 'styled-components'

import Layout from "../components/layout"
import SEO from "../components/seo"
import getPetList from "../mock/getPetList"
import { useState } from "react"
import { useHover } from "react-use"
import { Swiper as SwiperClass } from "swiper/types"


const Banner = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: stretch;
`

const BannerLeft = styled.div`
  position: relative;
  flex: 1;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  .waiting-title {
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #333333;
    line-height: 33px;
    
    padding-top: 20px;
  }
  
  .title {
    width: 100%;
    font-size: 50px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #333333;
    line-height: normal;
    
    padding-top: 50px;
  }
  
  .subtitle {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #333333;
    
    padding-top: 10px;
  }
`

const DownloadBtns = styled.div`
  display: flex;
  align-items: center;

  padding-top: 25px;

  .btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 116px;
    height: 44px;
    background: #ffd101;
    border-radius: 8px;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #333333;

    margin-right: 20px;

    &:hover {
      cursor: pointer;
      background: rgba(255, 209, 1, 0.8);
    }

    .qrcode {
      pointer-events: none;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 160px;
      height: 160px;
      padding: 10px;
      background-color: #ffffff;
      border-radius: 10px;
    }
  }
`

const BannerRight = styled.div<{ url: string; }>`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #FFFFFF;
  background-image: url(${prop => prop.url});
  background-repeat: no-repeat;
  background-size: 492px 369px;
  background-position: center;
`


const Belt = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  
  margin-top: 30px;
  
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .title {
      font-size: 20px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: center;
      color: #333333;
      margin-top: 15px;
    }
    
    .subtitle {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Light;
      font-weight: 300;
      text-align: center;
      color: #666666;
      line-height: 34px;
    }
  }
`

const Icon = styled.div<{ url: string; }>`
  width: 80px;
  height: 80px;
  background-image: url(${prop => prop.url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

const Tab = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  
  .item {
    width: 120px;
    height: 60px;
    font-size: 22px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #333333;
    line-height: 34px;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .indicator {
    width: 27px;
    height: 8px;
    opacity: 1;
    background: linear-gradient(270deg,rgba(255,234,2,0.00), #ffd101);
    border-radius: 12px;
    
    position: absolute;
    top: 80%;
    left: 50%;
  }
`

const Goods = styled.div`
  width: 100%;
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-row-gap: 24px;
  
  .item {
    width: 230px;
    height: 324px;
    opacity: 1;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    
    padding: 10px 10px 20px;
    
    margin: 0 18px;
    transition: all 300ms;

    &:hover {
      box-shadow: 0px 4px 8px 2px rgba(0,0,0,0.08);
      cursor: pointer;
      transform: scale(1.02);
    }
    
    .cover {
      width: 210px;
      height: 210px;
      opacity: 1;
      border-radius: 12px;
      background-color: #f4f4f4;
      
      background-repeat: no-repeat;
      background-size: cover;
    }
    
    .title-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 22px;
    }
    
    .info-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }
    
    .name {
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #323334;
    }
    
    .price-symbol {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #fd8712;
      line-height: 22px;
    }
    
    .price {
      font-size: 20px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #fd8712;
      line-height: 28px;
    }
    
    .avatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
    
    .nickname {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #9d9e9f;
      line-height: 20px;
      
      margin-left: 6px;
      
      max-width: 115px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    
    .area {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #9d9e9f;
      line-height: 20px;
    }
    
    .v-center {
      display: flex;
      align-items: center;
    }
  }
`

const SlideArea = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  .caret {
    position: absolute;
    width: 50px;
    height: 100%;
    top: 50%;
    fill: #DADADA;
    
    &:hover {
      fill: #FFD101;
    }
    
    &.left {
      left: 0;
      transform: translate(-100%, -50%);
    }
    
    &.right {
      right: 0;
      transform: translate(100%, -50%) rotate(180deg);
    }
  }
  
  .top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    .center {
      position: relative;
      width: 600px;
      height: 600px;
      border-radius: 20px;

      .image {
        width: 600px;
        height: 600px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }
    }
    
    .overflow-hidden {
      overflow: hidden;
    }
  }
  
  .bottom {
    width: 100%;
    height: 148px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: stretch;
    
    .restrict {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 44px;
      box-sizing: border-box;
      width: 1024px;
    }
    
    .left-outer {
      display: flex;
      align-items: center;
      
      .logo {
        width: 80px;
        height: 80px;
        background-image: url(${require('../images/logo.svg').default});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }
      
      .left-inner {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        
        .logo-text {
          width: 123px;
          height: 32px;
          background-image: url(${require('../images/logo-text.svg').default});
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
        
        .text {
          margin-top: 10px;
          font-size: 18px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #666666;
          line-height: 25px;
        }
      }
    }
  }
`

const StyledClose = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  background-image: url(${require('../images/close.svg').default});
  background-size: contain;
  background-position: center center;
  transform: rotate(0deg);
  transition: transform .3s;
  opacity: 0.8;
  
  &:hover {
    cursor: pointer;
    //transform: rotate(90deg);
  }
`


const goodsList = getPetList()

const IndexPage = () => {
  // const [goodsList, setGoodsList] = useState([])
  const [current, setCurrent] = useState(-1)
  const [swiper, setSwiper] = useState<SwiperClass>()

  // useEffect(() => {
  //   const petList = getPetList()
  //   setGoodsList(petList)
  // }, [])
  // console.log('render')
  const [hoverable1, hovered1] = useHover((hovered) => <div className="btn">
    苹果下载
    {
      hovered && <div className="qrcode">
        <img src={require('../images/QRCode.png').default} alt="下载App" />
      </div>
    }
  </div>)
  const [hoverable2, hovered2] = useHover((hovered) => <div className="btn">
    安卓下载
    {
      hovered && <div className="qrcode">
        <img src={require('../images/QRCode.png').default} alt="下载App" />
      </div>
    }
  </div>)

  return (
    <Layout>
      <SEO title="首页" />
      <Banner>
        <BannerLeft>
          <div className="title">有只宠物移动端</div>
          {/*<div className="waiting-title">APP、小程序开发中，敬请期待…</div>*/}
          <div className="subtitle">下载【有只宠物】APP，宠物领养寄养配种问答养宠物必备！</div>
          <DownloadBtns>
            {hoverable1}
            {hoverable2}
          </DownloadBtns>
        </BannerLeft>
        <BannerRight url={require("../images/mobile.png").default}/>
      </Banner>

      {/*<Tab>*/}
      {/*  <div className="item">推荐</div>*/}
      {/*  <div className="item">狗狗</div>*/}
      {/*  <div className="item">猫咪</div>*/}
      {/*  <div className="item">异宠</div>*/}

      {/*  <div className="indicator"></div>*/}
      {/*</Tab>*/}

      <Goods>
        {
          goodsList.map((pet, key) => {
            return <div
              key={key}
              className="item"
              onClick={() => setCurrent(key)}
            >
              <div
                className="cover"
                style={{backgroundImage: `url(${pet.pics[0]})`}}
              />
              <div className="title-row">
                <div className="name">{pet.name}</div>
                <div><span className="price-symbol">¥</span><span className="price">{ pet.price }</span></div>
              </div>
              <div className="info-row">
                <div className="v-center">
                  <img src={pet.shop.avatarUrl} className="avatar" />
                  <span className="nickname">{pet.shop.nickname}</span>
                </div>
                <div className="area">{pet.shop.area}</div>
              </div>
            </div>
          })
        }
      </Goods>
    </Layout>
  )
}

export default IndexPage
